<template>

  <Content>
    <template #contentBody>
      <a-spin size="large"
              :spinning="pageLoading">
        <a-row>
          <a-col :span="24">
            <a-steps :current="1"
                     style="max-width:1800px;"
                     size="small">
              <a-step :title="$t('logistics.create_transport_plan')" />
              <a-step :title="$t('logistics.set_transport_info')" />
              <a-step :title="$t('logistics.confirm_transport_plan')" />
              <a-step class="mr-3"
                      :title="$t('warehouse.order_placed')" />
            </a-steps>
          </a-col>
        </a-row>
        <!-- 仓库信息 -->
        <div class="inputs mt-3 mb-3">
          <a-row :gutter="[0,12]">
            <div class="ant-form-inline">

              <a-form-item :label="$t('logistics.transport_plan_name')">
                <span v-if="!isChangingPlanName">{{plan.name}}</span>
                <a-input v-else
                         ref="refPlanName"
                         size="small"
                         @pressEnter="handleSavePlanName"
                         @focus="(event) => {event.currentTarget.select();}"
                         v-model:value="plan.name" />
              </a-form-item>
              <a-form-item>
                <a-button v-if="!isChangingPlanName"
                          type="primary"
                          size="small"
                          @click="handleButtonAlterPlanName"
                          ghost>{{$t('account.modify')}}</a-button>
                <a-button v-else
                          type="primary"
                          size="small"
                          @click="handleSavePlanName"
                          :loading="buttonSavePlanNameLoading">{{$t('common.save')}}</a-button>
              </a-form-item>
              <a-form-item>
                <a-button v-if="isChangingPlanName"
                          type="primary"
                          size="small"
                          @click="handleCancelAlterPlanName"
                          ghost>{{$t('common.cancel')}}</a-button>
              </a-form-item>
            </div>
            <a-col :span="24">
              <label>{{$t('warehouse.transportation_plan_number')}}:</label>
              {{plan.no}}
            </a-col>
            <a-col :span="6">
              <label>{{$t('logistics.destination_type')}}:</label>
              {{$t($enumLangkey('destinationType',plan.toWarehouseType))}}
            </a-col>
            <a-col v-if="plan.toWarehouseType==destinationTypeEnum.FBA"
                   :span="6">
              <label>{{$t('logistics.fba_warehouse_code')}}:</label>
              {{plan.toWarehouseCode}}
            </a-col>
            <a-col v-else-if="plan.toWarehouseType==destinationTypeEnum.mercadolibre"
                   :span="6">
              <label>{{$t('warehouse.fbm_warehouse_code')}}:</label>
              {{plan.toWarehouseCode}}
            </a-col>
            <a-col v-else-if="plan.toWarehouseType==destinationTypeEnum.SeWarehouse"
                   :span="6">
              <label>{{$t('logistics.warehouse_code')}}:</label>
              {{plan.toWarehouseCode}}
            </a-col>
            <a-col v-else-if="plan.toWarehouseType==destinationTypeEnum.OtherAddress" :span="6">
              <label>{{ $t('warehouse.destination') }}:</label>
              {{ plan.toName || '-' }}
            </a-col>
            <a-col :span="24">
              <label>{{$t('logistics.shipping_address')}}:</label>
              {{getAddress(plan.toAddressData)}}
              <span class="ml-5"
                    v-if="plan.toAddressData && plan.toWarehouseType == destinationTypeEnum.SeWarehouse">{{ $t('logistics.contacts') }}: {{getLinkman(plan.toAddressData)}}</span>
            </a-col>

            <a-col :span="24">
              <label>{{$t('logistics.originate')}}:</label>
              {{plan.fromAddressData.fromName}}
            </a-col>
            <a-col :span="24">
              <label>{{$t('logistics.shipments_address')}}:</label>
              {{getAddress(plan.fromAddressData)}}
              <a-tag color="warning"
                     v-if="plan.toAddressData.isInternationalTransport"> {{$t('warehouse.international_transport')}}</a-tag>
            </a-col>

            <a-col :span="24"
                   v-if="plan.toWarehouseType==destinationTypeEnum.FBA">
              <label>{{$t('warehouse.amazon_shipping_number')}}:</label>
              {{plan.fbaTransportNo}}
            </a-col>
            <a-col :span="24"
                   v-if="plan.toWarehouseType==destinationTypeEnum.mercadolibre">
              <label>{{$t('warehouse.seller_id')}}:</label>
              {{plan.fbaTransportNo}}
            </a-col>
            <a-col :span="24"
                   v-if="plan.toWarehouseType==destinationTypeEnum.FBA">
              <label>{{$t('warehouse.shipment_tracking_number')}}:</label>
              {{plan.productTrackNo}}
            </a-col>
            <a-col :span="24"
                   v-if="plan.toWarehouseType==destinationTypeEnum.mercadolibre">
              <label>{{$t('warehouse.warehousing_number')}}:</label>
              {{plan.productTrackNo}}
            </a-col>
          </a-row>
        </div>
        <!-- 箱表格 -->
        <div>
          <a-table
            :columns="plan.boxTableColumns"
            :data-source="plan.producTableList"
            size="small"
            :expanded-row-keys="plan.boxExpandedRowKeysList"
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
            :pagination="{
              defaultPageSize: currentPageSize,
              hideOnSinglePage: true,
              current: currentPageIndex,
              position: 'both',
            }"
            :row-key="(record) => record.rowKey"
            @change='(page) => {currentPageIndex = page.current;}'
          >
            <template #boxNoTitle>
              <div>{{ $t("warehouse.box_no") }}</div>
              <div>{{ $t("common.user_defined_box_no") }}</div>
            </template>
            <template #encasementCount>
              <span>
                {{ $t("warehouse.encasement_count") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_1')" />
              </span>
            </template>
            <template #encasementSize>
              <span>
                {{ $t("warehouse.encasement_size") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_4')" />
              </span>
            </template>
            <template #encasementGrossWeight>
              <span>
                {{ $t("warehouse.encasement_weight") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_5')" />
              </span>
            </template>
            <template #inWarehouseBoxCount>
              <span>
                {{ $t("logistics.transport_box_number") }}
              </span>
            </template>

            <template #boxNo="{ record }">
              <div>
                {{ record.boxNo }}
                <CTagMixedOrSingle :productKindCount="record.productKindCount * 1"></CTagMixedOrSingle>
              </div>
              <div class="mt-1">
                <UserDefinedBoxNo :boxId="record.whBoxId" :userDefinedBoxNo="record.selfBoxNo"></UserDefinedBoxNo>
              </div>
            </template>
            <template #expandIcon="{ expanded, record }">
              <CHaveProductInformation :open="expanded" @click="handleOpenOrCloseRow(record)" />
            </template>
            <template #encasementCountCustom="{ record }">
              {{record.encasementCount}} Unit
            </template>
            <template #encasementSizeCustom="{ record }">
              {{record.encasementLength}}x{{record.encasementWidth}}x{{record.encasementHeight}}cm
            </template>
            <template #encasementGrossWeightCustom="{ record }">
              {{record.encasementGrossWeight}}kg
            </template>
            <template #operation="{ record }">
              <a-button :disabled="!!plan.inWarehouseNo || !!plan.outWarehouseNo" @click="handleOpenEditModal(record)">{{ $t('common.edit') }}</a-button>
            </template>
            <template #footer>
              <a-row type="flex"
                     justify="start"
                     class="mr-5"
                     :gutter="32">
                <a-col>{{$t('common.total')}}: {{plan.boxTotalCount}}{{$t('warehouse.box')}}</a-col>
                <a-col v-if="plan.transportTotalVolume>0">{{$t('warehouse.total_volume_of_transport')}}: {{plan.transportTotalVolume}}m<sup>3</sup></a-col>
                <a-col v-if="plan.transportTotalWeight>0">{{$t('warehouse.gross_shipping_weight')}}: {{plan.transportTotalWeight}}kg</a-col>
                <a-col>{{$t('warehouse.total_volume')}}: {{plan.totalContainerVolume}}m<sup>3</sup></a-col>
                <a-col>{{$t('warehouse.total_encasement_weight')}}: {{plan.totalContainerWeight}}kg</a-col>
                <a-col>
                  {{$t('warehouse.transport_characteristics')}}:
                  <span v-if="plan.transportTotalCharacteristic && !!plan.transportTotalCharacteristic.length">
                    <a-tag color="default"
                          v-for="item in plan.transportTotalCharacteristic"
                          :key="item">
                      {{$t($enumLangkey('transportCharacteristics',item))}}
                    </a-tag>
                  </span>
                  <span v-else>-</span>
                </a-col>
              </a-row>
            </template>
            <template #expandedRowRender="{ record }">
              <a-table
                :columns="plan.innerColumns"
                :data-source="record.productInfos"
                :pagination="false"
                :row-key="(record) => record.productId"
                size="small"
              >
                <template #productInfo="{ record }">
                  <a-row :gutter="8" type="flex" align="middle">
                    <a-col>
                      <div class="table-list-img-common">
                        <c-image
                          :src="record.productImgUrl"
                          :thumbWidth="600"
                          :thumbHeight="600"
                        />
                      </div>
                    </a-col>
                    <a-col flex="1">
                      <div>{{ record.productName }}</div>
                      <div>{{ record.productNo }}</div>
                    </a-col>
                  </a-row>
                </template>
                <template #containerCount="{ record }">
                  {{ record.containerCount }} Unit
                </template>
                <template #restsCustom="{ record }">
                  <a-row :gutter="[0,2]">
                    <a-col :span="24">
                      <a-row type="flex"
                             justify="space-between">
                        <a-col>{{$t('warehouse.customs_clearance_info')}}</a-col>
                        <a-col>
                          <a-button size="small"
                                    :loading="record.loading"
                                    @click="toLoadDefaultData(record)">{{$t('warehouse.loading_default_settings')}}</a-button>
                        </a-col>
                      </a-row>
                    </a-col>
                    <a-col :span="24">
                      <a-input :addon-before="$t('warehouse.chinese_trade_name')"
                               size="small"
                               v-model:value="record.chName" />
                    </a-col>
                    <a-col :span="24">
                      <a-input :addon-before="$t('warehouse.english_trade_name')"
                               size="small"
                               v-model:value="record.enName" />
                    </a-col>
                    <a-col :span="24">
                      <a-row type="flex"
                             justify="space-between">
                        <a-col :span="11">
                          <a-input addon-before="HS  Code"
                                   size="small"
                                   v-model:value="record.hsCode" />
                        </a-col>
                        <a-col :span="12">
                          <a-input-group compact>
                            <div style="border:1px solid #d9d9d9; padding: 0 11px; background-color: #fff;">
                              {{$t('warehouse.declare_price')}}
                            </div>
                            <a-select v-model:value="record.currencyId"
                                      size="small">
                              <a-select-option v-for="item in currencys"
                                               :key="item.id"
                                               :value="item.id"
                                               :title="item.symbol">{{item.symbol}}</a-select-option>
                            </a-select>
                            <a-input-number style="width: 40%"
                                     size="small"
                                     :step="1"
                                        :precision="2"
                                        v-model:value="record.declaredPrice" />
                          </a-input-group>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </template>
              </a-table>
            </template>
          </a-table>
        </div>
        <!-- 物流方案 -->
        <div>
          <a-button type="primary"
                    class="mt-2 mb-2"
                    :loading="freightEstimateLoading"
                    @click="handleFreightEstimate">
            {{$t('logistics.select_logistics_plan')}}
          </a-button>
          <div v-if="logistics.isSearch" class="mb-3">
            <a-tabs v-if="!logistics.selectedLogisticsSchemeId && isShowCalculationMethod" v-model:activeKey="calculationMethod" @change="handleChangeLogisticsScheme">
              <a-tab-pane :key="feeCalculationMethodEnum.template" :disabled="logisticsSchemeLoading" :tab="$t('logistics.logistics')"></a-tab-pane>
              <a-tab-pane :key="feeCalculationMethodEnum.truckApi" :disabled="logisticsSchemeLoading" :tab="$t('logistics.truck')"></a-tab-pane>
            </a-tabs>
            <a-table
              :columns="logistics.columnsLogisticsScheme"
              :data-source="logistics.logisticsSchemeExhibitionList"
              :pagination="false"
              size="small"
              :scroll="{ x:1200,y:1000}"
              :loading="logisticsSchemeLoading"
              :locale="{emptyText:$t('logistics.not_currently_available_logistics_scheme')}"
            >
              <template #optionalServices v-if="logistics.selectedLogisticsSchemeId">
                <span>
                  {{ $t("warehouse.optional_services") }}
                </span>
              </template>
              <template #optionCustom="{ record }" v-if="!logistics.selectedLogisticsSchemeId">
                <a-button @click="handleSelectedLogistics(record)">{{$t('common.select')}}</a-button>
              </template>
              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">{{record.scheme}} / {{record.code}}</div>
                <a-row :gutter=[8,8]>
                  <a-col v-for="item in record.descriptionItems" :key="item">
                    <CheckCircleOutlined class="text-warning mr-1" :style="{fontSize: '12px'}" />
                    <span style="font-size: 12px; color: rgba(102,102,102,1)">
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>
              <template #logisticsWayCustom="{ record }">
                {{$t($enumLangkey('logisticsWay',record.logisticsWay))}}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{$t($enumLangkey('deliveryWay',record.deliveryWay))}}
              </template>
              <template #referenceAgingCustom="{ record }">
                <div>
                  {{record.daysMin}}-{{record.daysMax}}
                  {{$t("warehouse.workday")}}
                  ({{$t($enumLangkey('aging',record.channelTimeType))}})
                </div>
              </template>
              <template #freightsCustom="{ record }">
                <div class="text-warning">
                  {{$t('warehouse.average_unit_price')}}: {{record.currencySymbol}}{{$filters.amountToFixed2(record.averagePrice)}}
                </div>
                <div class="text-warning">
                  {{$t('warehouse.total_prices')}}: {{record.currencySymbol}}{{$filters.amountToFixed2(record.expressFee)}}
                </div>
                <div style="font-size: 10px; color: #808080;"
                     v-if="!logistics?.selectedLogisticsSchemeId">
                  {{$t('warehouse.tooltip_text_6')}}
                </div>
              </template>
              <template #detailsCustom="{ record }">
                <div v-if="record.logisticsCalcMethod==0">
                  {{$t('logistics.chargeable_weight')}}: {{record.chargeableWeight}}kg
                </div>
                <div v-else>
                  {{$t('logistics.chargeable_volume')}}: {{record.chargeableVolume}}m<sup>3</sup>
                </div>
                <div>
                  {{$t('logistics.volume_weight_coefficient')}}{{record.volumeWeightParam}}
                </div>
              </template>
              <template #optionalServicesCustom="{ record }">
                <template v-if="logistics.selectedLogisticsSchemeId">
                  <div v-if="record.logisticsRegionInfo.isDdpSupported">
                    <a-checkbox v-model:checked="logistics.isSelectedDDP">
                      DDP({{record.currencySymbol}}{{$filters.amountToFixed2(record.logisticsRegionInfo.ddpFee)}})
                    </a-checkbox>
                  </div>
                  <CSignSupported
                    v-if="record.logisticsRegionInfo.isSignSupported"
                    v-model:value="logistics.signatureType"
                    type="editable"
                    :symbol="record.currencySymbol"
                    :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                  />
                </template>
                <template v-else>
                  <div v-if="record.logisticsRegionInfo.isDdpSupported">
                    DDP({{record.currencySymbol}}{{$filters.amountToFixed2(record.logisticsRegionInfo.ddpFee)}})
                  </div>
                  <CSignSupported
                    v-if="record.logisticsRegionInfo.isSignSupported"
                    type="preview"
                    :symbol="record.currencySymbol"
                    :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                  />
                </template>
              </template>
              <!-- 卡车专属 -->
              <template #quoteOptionsTitle="{ record }">
                {{ $t('logistics.quote_options') }}
                <CTooltip :title="$t('logistics.quote_options_tips')" />
              </template>
              <template #quoteOptions="{ record }">
                <a-row :gutter="[16, 8]">
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px"><span class="text-error">*</span>{{ $t('logistics.quote_type') }}</a-col>
                      <a-col flex="auto">
                        <a-select
                          v-if="!logistics.selectedLogisticsSchemeId"
                          v-model:value="record.quoteTypeId"
                          size="small"
                          style="width: 150px;"
                          :disabled="record.loading"
                          @change="record.quoteDetail = null"
                        >
                          <a-select-option
                            v-for="(item, index) in quoteTypeList"
                            :key="index"
                            :value="item.id"
                            :title="item.name"
                          >{{ item.name }}</a-select-option>
                        </a-select>
                        <span v-else>{{ record.truckBaseInfo.quoteType?.name }}</span>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24" v-if="handleShowServiceLevelSelect(record)">
                    <a-row type="flex">
                      <a-col flex="100px"><span class="text-error">*</span>{{ $t('logistics.service_level') }}</a-col>
                      <a-col flex="1">
                        <a-select
                          v-if="!logistics.selectedLogisticsSchemeId"
                          v-model:value="record.serviceLevel"
                          size="small"
                          style="width: 150px;"
                          :disabled="record.loading"
                          @change="record.quoteDetail = null"
                        >
                          <a-select-option
                            v-for="(item, index) in serviceLevelList"
                            :key="index"
                            :value="item.id"
                            :title="item.name"
                          >{{ item.name }}</a-select-option>
                        </a-select>
                        <template v-else>
                          <span>{{ record.truckBaseInfo.serviceLevel.name }}</span>
                          <span>-</span>
                        </template>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px"><span>&nbsp;&nbsp;</span>{{ $t('logistics.additional_options') }}</a-col>
                      <a-col flex="1">
                        <a-select
                          v-if="!logistics.selectedLogisticsSchemeId"
                          v-model:value="record.accessorials"
                          mode="multiple"
                          size="small"
                          style="width: 150px;"
                          :disabled="record.loading"
                          @change="record.quoteDetail = null"
                        >
                          <a-select-option
                            v-for="(item, index) in additionalOptionsList"
                            :key="index"
                            :value="item.id"
                            :title="item.name"
                          >{{ item.name }}</a-select-option>
                        </a-select>
                        <span v-else>
                          <a-popover trigger="hover">
                            <template #content>
                              <div style="max-width: 300px;">{{ getAccessorialsName(record.truckBaseInfo.accessorials) }}</div>
                            </template>
                            <div style="max-width: 250px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ getAccessorialsName(record.truckBaseInfo.accessorials) }}</div>
                          </a-popover>
                        </span>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px"></a-col>
                      <a-col flex="1">
                        <a-button v-if="!logistics.selectedLogisticsSchemeId" size="small" type="ghost" :loading="record.loading" @click="handleSearchTruckFee(record)">{{ $t('logistics.inquiry_immediately') }}</a-button>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </template>
              <template #quoteDetails="{ record }">
                <template v-if="!logistics.selectedLogisticsSchemeId">
                  <a-space>
                    <span>{{ $t('logistics.quote') }}:</span>
                    <span v-if="record.quoteDetail">{{ record.quoteDetail.carrierName }} [{{ $filters.formatCurrencyAmount(record.quoteDetail.currencySymbol, record.quoteDetail.totalCharge, false) }}, {{ record.quoteDetail.transitTime }} day(s)]</span>
                    <span v-else>-</span>
                  </a-space>
                  <div class="mt-3" v-if="record.quoteDetail">
                    <a-button type="ghost" :disabled="record.loading" @click="handleSelectedLogistics(record)">{{ $t('common.confirm') }}</a-button>
                  </div>
                </template>
                <template v-else>
                  <span v-if="record.truckBaseInfo">{{ record.truckBaseInfo.carrierName }} [{{ $filters.formatCurrencyAmount(record.truckBaseInfo.currencySymbol, record.truckBaseInfo.totalCharge, false) }}, {{ record.truckBaseInfo.transitTime }} day(s)]</span>
                  <span v-else>-</span>
                </template>
              </template>
            </a-table>

            <div class="mt-2">
              <a-tag color="warning"
                     v-if="logistics.isRemoteArea">{{$t('logistics.remote_site')}}</a-tag>
              <template v-if="logistics.isCommercial!=null">
                <a-tag color="warning"
                       v-if="logistics.isCommercial">{{$t('logistics.business_site')}}</a-tag>
                <a-tag color="warning"
                       v-else>{{$t('logistics.residence')}}</a-tag>
              </template>
            </div>

            <a-form-item class="mt-3" v-show="logistics.isShowVATInput">
              <template #label>
                <div style="width: 150px;">
                  <span class="text-error"> * </span>
                  <label>VAT</label>
                </div>
              </template>
              <a-input style="max-width:250px"
                       v-model:value="logistics.VAT" />
            </a-form-item>
          </div>
        </div>
        <div class="mt-3" v-if="plan.toAddressData.isInternationalTransport">
          <a-form-item>
            <template #label>
              <div style="width: 150px;">
                <span style="color:red"> * </span>
                <label>{{ $t('logistics.purchase_insurance') }}</label>
              </div>
            </template>
            <a-select v-model:value="plan.isInsure" :placeholder="$t('common.please_select')" style="max-width:250px">
              <a-select-option :value="1">{{ $t('common.necessary') }}</a-select-option>
              <a-select-option :value="0">{{ $t('common.unnecessary') }}</a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <!-- 报关方式 -->
        <div v-show="plan.toAddressData.isInternationalTransport&&isDeclareSupported">
          <p><strong>{{$t('logistics.clearance_way')}}</strong></p>
          <a-checkbox v-model:checked="isSelectedCustoms">
            {{$t('logistics.ordinary_trade_clearance')}}
            </a-checkbox>
          <div class="p-3"
               v-if="isSelectedCustoms">
            <a-radio-group v-model:value="updateFileType"
                           class="ml-5 "
                           size="small"
                           button-style="solid">
              <a-radio-button value="separateOfFile">{{$t('logistics.separate_file')}}</a-radio-button>
              <a-radio-button value="fourInOne">{{$t('logistics.four_in_one_file')}}</a-radio-button>
            </a-radio-group>
          </div>
          <a-row v-if="isSelectedCustoms">
            <a-col :md="15"
                   :lg="11"
                   :xl="10"
                   :xxl="7">
              <div class="p-3"
                   v-if="updateFileType=='separateOfFile'">
                <a-row>
                  <a-col :span="4">{{$t('logistics.customs_declaration')}}</a-col>
                  <a-col :span="6">
                    <CustomsUpload v-model:fileUrl="files.customsDeclarationUrl"></CustomsUpload>
                  </a-col>
                  <a-col>{{$t('logistics.support_pdf_format')}}</a-col>
                </a-row>

                <a-row class="mt-4">
                  <a-col :span="4">
                    {{$t('logistics.contract')}}
                  </a-col>
                  <a-col :span="6">
                    <CustomsUpload v-model:fileUrl="files.contractUrl"></CustomsUpload>
                  </a-col>
                  <a-col>{{$t('logistics.support_pdf_format')}}</a-col>
                </a-row>

                <a-row class="mt-4">
                  <a-col :span="4">{{$t('logistics.invoice')}}</a-col>
                  <a-col :span="6">
                    <CustomsUpload v-model:fileUrl="files.invoiceUrl"></CustomsUpload>
                  </a-col>
                  <a-col>{{$t('logistics.support_pdf_format')}}</a-col>
                </a-row>

                <a-row class="mt-4">
                  <a-col :span="4">{{$t('logistics.packing_documents')}}</a-col>
                  <a-col :span="6">
                    <CustomsUpload v-model:fileUrl="files.packingUrl"></CustomsUpload>
                  </a-col>
                  <a-col>{{$t('logistics.support_pdf_format')}}</a-col>
                </a-row>
              </div>

              <div class="p-3"
                   v-if="updateFileType=='fourInOne'">
                <a-row>
                  <a-col :span="6">
                    {{$t('logistics.four_in_one_file')}}
                    <c-tooltip :title="$t('logistics.four_in_one_file_tooltip')" />
                  </a-col>
                  <a-col :span="6">
                    <CustomsUpload v-model:fileUrl="files.integrationUrl"></CustomsUpload>
                  </a-col>
                  <a-col>{{$t('logistics.support_pdf_format')}}</a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- button创建计划 -->
        <div class="mr-5 mb-5 ">
          <a-row type="flex" justify="end">
            <a-col v-if="plan.allowDelete">
              <a-button @click="handleDeletePlan">{{$t('warehouse.delete_transport_plan')}}</a-button>
            </a-col>
            <a-col class="ml-3">
              <a-button type="primary" @click="handleSavePlan">{{$t('common.save_and_next_step')}}</a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>
      <EditBoxSizeModal v-model:visible="editBoxSizeModalState.visible" :info="editBoxSizeModalState.info" @refresh="handleEditBoxAfter" />
      <QuotedPriceModal ref="quotedPriceModalRef" @confirm="handleConfirmQuotedPrice" />
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, nextTick } from "vue";
import {
  Row, Col, Steps, Tag,
  Table, Input, Checkbox, Radio,
  Select, Button, Form, Spin, Space, message,InputNumber, Tabs, Popover,
  Modal
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage.vue"
import CTooltip from "@/views/components/CTooltip.vue"
import UserDefinedBoxNo from "@/views/components/UserDefinedBoxNo.vue";
import CTagMixedOrSingle from "@/views/components/CTagMixedOrSingle.vue";
import CHaveProductInformation from "@/views/components/CHaveProductInformation.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import CustomsUpload from "../components/Upload.vue";
import QuotedPriceModal from '../components/QuotedPriceModal.vue';
import EditBoxSizeModal from './components/EditBoxSizeModal.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import { getCurrencys } from "@/api/modules/common/index";
import {
  getTransportPlanDetails,
  updatePlanName,
  getLogisticsAddressInfo,
  getCustomsInfo,
  getLogisticsScheme,
  deleteTransportPlan,
  savePlanSetBoxInfo
} from "@/api/modules/transportation/index";
import { useTab } from "@/hooks/tabs/index";
import { useSupplierHelper } from '@/utils/supplierHelper.js'
import { getAddressByLanguageV2, gToKg, cmCubicToM, getLinkman } from "@/utils/general"
import {
  destinationType as destinationTypeEnum,
  feeCalculationMethod as feeCalculationMethodEnum
} from "@/enum/enum.json";

export default ({
  name: "transport_set_plan_info",
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ATag: Tag,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ASpin: Spin,
    ACheckbox: Checkbox,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
    ASpace: Space,
    AInputNumber: InputNumber,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    APopover: Popover,
    CustomsUpload,
    Content,
    CImage,
    CTooltip,
    CHaveProductInformation,
    UserDefinedBoxNo,
    CTagMixedOrSingle,
    QuotedPriceModal,
    CSignSupported,
    EditBoxSizeModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();
    const supplierHelper = useSupplierHelper();

    const refPlanName = ref();
    const quotedPriceModalRef = ref(null);
    const currentPageIndex = ref(1);
    const currentPageSize = ref(50);

    const boxTableColumns = [
      {
        width: 60,
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ record }) => {
          return record.serialNumber
        },
      },
      {
        title: () => vueI18n.t('warehouse.box_no') + ' / ' + vueI18n.t('common.user_defined_box_no'),
        width: 150,
        slots: {
          title: "boxNoTitle",
          customRender: "boxNo"
        }
      },
      { width: 50, },
      {
        width: 150,
        slots: {
          title: "encasementCount",
          customRender: "encasementCountCustom",
        },
      },
      {
        width: 200,
        slots: {
          title: "encasementSize",
          customRender: "encasementSizeCustom",
        },
      },
      {
        width: 150,
        slots: {
          title: "encasementGrossWeight",
          customRender: "encasementGrossWeightCustom",
        },
      },
      {
        width: 120,
        slots: {
          customRender: "operation",
        },
      },
    ];

    const innerProductColumns = [
      { width: 60, },
      {
        width: 450,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        width: 200,
        title: ('SESKU'),
        dataIndex: "seSku",
      },
      {
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        width: 500,
        dataIndex: 'rests',
        title: vueI18n.t('warehouse.rests'),
        align: "right",
        slots: {
          customRender: "restsCustom",
        },
      },
    ];

    // 物流可选服务
    const columnsLogisticsScheme = [
      {
        slots: {
          customRender: "optionCustom",
        },
        width: 100,
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.logistics_scheme") + '/' + vueI18n.t('warehouse.logistics_code'),
        slots: {
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.logistics_type"),
        slots: {
          customRender: "logisticsWayCustom",//物流名称和基础服务
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.delivery_way"),
        slots: {
          customRender: "deliveryWayCustom",
        },
      },
      {
        key: "daysMin",
        width: 100,
        title: () => vueI18n.t("warehouse.reference_aging"),
        slots: {
          customRender: "referenceAgingCustom",
        },
        sorter: (a, b) => a.daysMin - b.daysMin,
        sortDirections: ['ascend', 'descend'],
      },
      {
        key: "expressFee",
        width: 220,
        title: () => vueI18n.t("warehouse.freight_estimate"),
        slots: {
          customRender: "freightsCustom",//平均单价和合计
        },
        sorter: (a, b) => a.expressFee - b.expressFee,
        sortDirections: ['ascend', 'descend'],
      },
      {
        width: 180,
        title: () => vueI18n.t("common.details"),
        slots: {
          customRender: "detailsCustom",//计费重、体积重系数：除600
        },
      },
      {
        width: 180,
        title: () => vueI18n.t("warehouse.optional_services"),
        slots: {
          customRender: "optionalServicesCustom",
        },
      }
    ];

    // 卡车可选服务
    const columnsLogisticsTruck = [
      {
        width: 200,
        title: () => vueI18n.t("warehouse.logistics_scheme") + '/' + vueI18n.t('warehouse.logistics_code'),
        slots: {
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.logistics_type"),
        slots: {
          customRender: "logisticsWayCustom",//物流名称和基础服务
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.delivery_way"),
        slots: {
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 200,
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t('logistics.quote_details'),
        slots: {
          customRender: "quoteDetails",
        },
      },
    ]

    const getLogisticsData = () => {
      return {
        isSearch: false,
        columnsLogisticsScheme: [],
        selectedLogisticsSchemeId: null,
        selectedLogisticsDeliveryType: null,

        logisticsSchemeList: [],
        logisticsSchemeExhibitionList: [],

        isShowVATInput: false,
        VAT: null,
        isSelectedDDP: false,
        isSelectedSignFee: false,
        signatureType: null,
        isRemoteArea: null,//是否为偏远地址
        isCommercial: null,//是否为商业地址
      }
    }

    const state = reactive({
      destinationTypeEnum, destinationTypeEnum,
      pageLoading: false,
      buttonCreatePlanLoading: false,
      buttonSavePlanNameLoading: false,
      isChangingPlanName: false,
      currencys: [],
      plan: {
        boxTableColumns,
        innerColumns: innerProductColumns,
        id: "",
        no: "",
        name: "",
        toWarehouseType: null,
        toWarehouseId: "",
        toWarehouseCode: "",
        toName: "",
        fbaTransportNo: "",
        productTrackNo: "",
        fromAddressData: {},
        toAddressData: {},
        producTableList: [],
        boxExpandedRowKeysList: [],
        boxTotalCount: 0,
        transportTotalVolume: 0,
        transportTotalWeight: 0,
        transportTotalCharacteristic: null,
        totalContainerVolume: 0,
        totalContainerWeight: 0,
        inWarehouseNo: null,
        outWarehouseNo: null,
        isInsure: null,   // 是否需要保险
      },
      isShowCalculationMethod: true,
      calculationMethod: 0,
      // 卡车-报价类型
      quoteTypeList: [],
      // 卡车-服务等级
      serviceLevelList: [],
      // 卡车-附加选项
      additionalOptionsList: [],
      // 弹窗表格数据
      quotedPriceList: [],
      freightEstimateLoading: false,
      logisticsSchemeLoading: false,
      logistics: getLogisticsData(),
      isSelectedCustoms: false,
      isDeclareSupported: false,//是否支持一般贸易报关,
      updateFileType: 'separateOfFile',//separateOfFile 和 fourInOne,
      files: {
        customsDeclarationUrl: null,
        contractUrl: null,
        invoiceUrl: null,
        packingUrl: null,
        integrationUrl: null
      },
    })

    const editBoxSizeModalState = reactive({
      visible: false,
      info: null,
    });

    const handleOpenEditModal = (record) => {
      editBoxSizeModalState.visible = true;
      editBoxSizeModalState.info = record;
    }

    const handleEditBoxAfter = () => {
      funcSearchPlanInfo(state.plan.id);
      state.logistics.selectedLogisticsSchemeId = "";
      state.logistics.isSearch = false;
      state.logistics.logisticsSchemeList = [];
      state.logistics.logisticsSchemeExhibitionList = [];
    }

    //币种
    const funcGetCurrencys = () => {
      return getCurrencys({ "isActive": true }).then((obj) => {
        let { result } = obj;
        state.currencys = result;
      });
    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const getAccessorialsName = (list) => {
      if (!Array.isArray(list)) {
        return '-'
      }
      return list.map(item => item.name).join(', ')
    };

    // 展开关闭行
    const handleOpenOrCloseRow = (record) => {
      let rowKey = record.rowKey;
      if (state.plan.boxExpandedRowKeysList.includes(rowKey)) {
        state.plan.boxExpandedRowKeysList = state.plan.boxExpandedRowKeysList.filter(item => item !== rowKey);
      } else {
        state.plan.boxExpandedRowKeysList.push(rowKey);
      }
    }

    const funcSearchPlanInfo = async (planId) => {
      state.pageLoading = true;
      getTransportPlanDetails({ "id": planId })
        .then((res) => {
          try {
            let r = res.result;
            state.plan.id = r.planBasicInfo.planId;
            state.plan.no = r.planBasicInfo.planNo;
            state.plan.name = r.planBasicInfo.planName;
            state.plan.toWarehouseType = r.planBasicInfo.destinationType;//目的仓库类型
            state.plan.toWarehouseId = r.planBasicInfo.toWarehouseId;
            state.plan.toWarehouseCode = r.planBasicInfo.warehouseCode;
            state.plan.toName = r.planBasicInfo.toName;
            state.plan.fbaTransportNo = r.planBasicInfo.amazonTransportNo;
            state.plan.productTrackNo = r.planBasicInfo.trackNo;
            state.plan.fromAddressData = r.planBasicInfo.fromAddress;
            state.plan.fromAddressData.fromName = r.planBasicInfo.fromName;
            state.plan.toAddressData = r.planBasicInfo.toAddress;
            state.plan.toAddressData.isInternationalTransport = r.planBasicInfo.isInternationalTransport;//是否国际运输
            state.plan.allowDelete = r.planBasicInfo.allowDelete;
            state.plan.inWarehouseNo = r.planBasicInfo.inWarehouseNo;
            state.plan.outWarehouseNo = r.planBasicInfo.outWarehouseNo;
            state.plan.isInsure = r.planBasicInfo.isInsure === true ? 1 : r.planBasicInfo.isInsure === false ? 0 : null;

            // state.isSelectedCustoms = r.isUploadHsInfo;

            if (r.isUploadHsInfo) {
              state.files = r.customsWayInfo;
              state.updateFileType = state.files.integrationUrl ? 'fourInOne' : 'separateOfFile'
            }

            state.plan.producTableList = r.planBoxInfo.transportPlanBoxData.map((x, index) => {
              let productInfos = x.productInfos.map(product => {
                return {
                  detailId: product.detailId,
                  id: product.id,
                  productId: product.productId,
                  productImgUrl: product.productImgUrl,
                  productName: product.productName,
                  productNo: product.productNo,
                  seSku: product.seSku,
                  containerCount: product.containerCount,
                  chName: product.customsInfo?.brandCName,
                  enName: product.customsInfo?.brandEnName,
                  hsCode: product.customsInfo?.hsCode,
                  currencyId: product.customsInfo?.currencyId ?? '2',//2为美元的id
                  declaredPrice: product.customsInfo?.totalPrice,
                };
              });

              state.plan.boxExpandedRowKeysList.push(x.planDetailId);
              return {
                id: x.planDetailId,
                planId: planId,
                boxId: x.boxId,
                rowKey: x.planDetailId,
                serialNumber: index + 1,
                boxNo: x.boxNo,
                whBoxId: x.whBoxId,
                selfBoxNo: x.selfBoxNo,
                encasementLength: x.packBoxInfo.length,
                encasementWidth: x.packBoxInfo.width,
                encasementHeight: x.packBoxInfo.height,
                encasementGrossWeight: x.packBoxInfo.weight ? gToKg(x.packBoxInfo.weight) : '0',
                encasementCount: x.containerCount,
                productKindCount: x.productKindCount,
                loading: false,
                quoteDetail: null,
                productInfos,
              }
            })

            state.plan.boxTotalCount = r.planBoxInfo.totalBox ?? "0";
            state.plan.transportTotalVolume = r.planBoxInfo.totalVolume ? cmCubicToM(r.planBoxInfo.totalVolume) : 0;
            state.plan.transportTotalWeight = r.planBoxInfo.totalBoxWeight ? gToKg(r.planBoxInfo.totalBoxWeight) : 0;
            state.plan.transportTotalCharacteristic = r.planBoxInfo.transportCharacteristics;

            state.plan.totalContainerVolume = r.planBoxInfo.totalContainerVolume ? cmCubicToM(r.planBoxInfo.totalContainerVolume) : 0;
            state.plan.totalContainerWeight = r.planBoxInfo.totalContainerWeight ? gToKg(r.planBoxInfo.totalContainerWeight) : 0;

            if (!state.plan.toAddressData.isInternationalTransport) {
              state.plan.innerColumns = innerProductColumns.filter(x => x.dataIndex != "rests");
            }
            state.pageLoading = false;
          } catch (error) {
            console.log('err', error);
          }
        })
        .catch((res) => {
          state.pageLoading = false;
        })
    };

    const handleSavePlanName = async () => {
      let par = {
        "id": state.plan.id,
        "planName": state.plan.name
      };
      state.buttonSavePlanNameLoading = true;
      updatePlanName(par)
        .then((res) => {
          message.success(vueI18n.t("common.amend_success"));
          state.isChangingPlanName = false;
          state.buttonSavePlanNameLoading = false;
        })
        .catch(() => {
          state.buttonSavePlanNameLoading = false;
        })
    }

    const handleCancelAlterPlanName = async () => {
      state.plan.name = state.tempPlanName;
      state.isChangingPlanName = false;
    }

    const handleButtonAlterPlanName = async () => {
      state.isChangingPlanName = true;
      state.tempPlanName = state.plan.name;
      nextTick(() => {
        refPlanName.value.focus();
      });
    }

    const toLoadDefaultData = (record) => {
      record.loading = true;
      getCustomsInfo({ productId: record.productId, countryId: state.plan.toAddressData.countryId }).then((res) => {
        let r = res.result;
        if (r) {
          record.chName = r.customsCnName;
          record.enName = r.customsEnName;
          record.hsCode = r.hsCode;
          record.currencyId = r.currencyId;
          record.declaredPrice = r.declarePrice;
        }
        record.loading = false;
      }).catch(() => {
        record.loading = false;
      })
    }

    const handleShowServiceLevelSelect = (record) => {
      let item = state.quoteTypeList.find(item => item.id === record.quoteTypeId)
      if (item) {
        return item.needService
      }
      return false
    }

    const getSupplierList = async () => {
      try {
        state.quoteTypeList = await supplierHelper.getQuoteTypeList()
        state.serviceLevelList = await supplierHelper.getServiceLevelList()
        state.additionalOptionsList = await supplierHelper.getAdditionalOptionsList()
      } catch (error) {
      }
    }

    const handleChangeLogisticsScheme = () => {
      state.logistics.logisticsSchemeExhibitionList = state.logistics.logisticsSchemeList.filter(item => item.calculationMethod === state.calculationMethod)
      if (state.logistics.logisticsSchemeList.length) {
        // 如果只有（物流或卡车中的）一种方案就不显示tab
        if (state.logistics.logisticsSchemeExhibitionList.length === 0 || state.logistics.logisticsSchemeExhibitionList.length === state.logistics.logisticsSchemeList.length) {
          state.isShowCalculationMethod = false
        } else {
          state.isShowCalculationMethod = true
        }
        if (state.logistics.logisticsSchemeExhibitionList.length === 0) {
          if (state.calculationMethod === feeCalculationMethodEnum.template) {
            state.calculationMethod = feeCalculationMethodEnum.truckApi
          } else {
            state.calculationMethod = feeCalculationMethodEnum.template
          }
          state.logistics.logisticsSchemeExhibitionList = state.logistics.logisticsSchemeList.filter(item => item.calculationMethod === state.calculationMethod)
        }
      } else {
        state.isShowCalculationMethod = false
      }
      if (state.calculationMethod === feeCalculationMethodEnum.template) {
        // 物流
        state.logistics.columnsLogisticsScheme = columnsLogisticsScheme;
      } else {
        // 卡车
        state.logistics.columnsLogisticsScheme = columnsLogisticsTruck;
        getSupplierList()
      }
    }

    //物流方案
    const handleFreightEstimate = () => {
      state.logistics = getLogisticsData();

      state.logistics.selectedLogisticsSchemeId = "";
      state.logistics.isSearch = true;

      state.freightEstimateLoading = true;
      state.logisticsSchemeLoading = true;
      getLogisticsScheme({ planId: state.plan.id })
        .then((res) => {
          if (res.result && res.result.length > 0) {
            state.logistics.logisticsSchemeList = res.result.map((x, index) => {
              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType,//签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice,//平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices,//合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol,//货号符号

                chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight ? gToKg(x.logisticsBaseInfo.chargeableWeight) : '0',//计费重
                chargeableVolume: cmCubicToM(x.logisticsBaseInfo.chargeableVolume),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,


                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam,//体积系数
                declareSupported: x.logisticsBaseInfo.declareSupported,//是否支持一般贸易报关
                logisticsRegionInfo: x.logisticsRegionInfo,//是否支持DDP和签字
                isVatRequired: x.logisticsRegionInfo.isVatRequired,//是否支持Vat

                // 卡车相关
                calculationMethod: x.logisticsBaseInfo.feeCalculationMethod,
                apiAccountId: x.logisticsBaseInfo.apiAccountId,
                loading: false,
              }
            })
          } else {
            state.logistics.logisticsSchemeList = [];
            state.logistics.logisticsSchemeExhibitionList = [];
          }
          handleChangeLogisticsScheme()
        }).finally(() => {
          state.freightEstimateLoading = false;
          state.logisticsSchemeLoading = false;
        })
    }

    const handleSelectedLogistics = (record) => {
      if (!record) {
        return
      }
      if (state.calculationMethod === feeCalculationMethodEnum.template) {
        // 物流
        state.logistics.columnsLogisticsScheme = state.logistics.columnsLogisticsScheme.filter(x => x.dataIndex != "option");
      }
      state.logisticsSchemeLoading = true;
      let pra = {
        planId: state.plan.id,
        logisticsId: record.id,
        deliveryType: state.logistics.selectedLogisticsDeliveryType,
        truckApiLogistics: null
      }
      // 判断是否是卡车
      if (state.calculationMethod === feeCalculationMethodEnum.truckApi) {
        pra.truckApiLogistics = {}
        pra.truckApiLogistics['quoteDetailId'] = record.quoteDetail.quoteDetailId
        pra.truckApiLogistics['quoteTypeId'] = record.quoteTypeId
        pra.truckApiLogistics['serviceLevel'] = record.serviceLevel
        pra.truckApiLogistics['accessorials'] = record.accessorials ?? []
      }
      getLogisticsAddressInfo(pra).then((res) => {
        if (res.result) {
          state.logistics.logisticsSchemeList = [];
          state.logistics.logisticsSchemeExhibitionList = [];

          let r = [res.result];
          state.logistics.isRemoteArea = res.result.logisticsBaseInfo.isRemoteArea;
          // 是否为商业地址: null - 未知地址; true - 商业地址; false - 私人(住宅)地址
          state.logistics.isCommercial = res.result.logisticsBaseInfo.isCommercial;

          //主要重新返回了新的费用
          state.logistics.logisticsSchemeList = r?.map((x, index) => {
            return {
              key: index,
              id: x.logisticsBaseInfo.id,
              scheme: x.logisticsBaseInfo.name,
              code: x.logisticsBaseInfo.code,
              descriptionItems: x.logisticsBaseInfo.descriptionItems,

              logisticsWay: x.logisticsBaseInfo.logisticsType,
              deliveryWay: x.logisticsBaseInfo.deliveryType,

              daysMin: x.logisticsBaseInfo.daysMin,
              daysMax: x.logisticsBaseInfo.daysMax,
              channelTimeType: x.logisticsBaseInfo.channelTimeType,//签收或自提 枚举

              averagePrice: x.logisticsBaseInfo.averagePrice,//平均费
              expressFee: x.logisticsBaseInfo.totalFeeWithoutServices,//合计费用
              currencySymbol: x.logisticsBaseInfo.currencySymbol,//货号符号

              chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight ? gToKg(x.logisticsBaseInfo.chargeableWeight) : '0',//计费重
              chargeableVolume: cmCubicToM(x.logisticsBaseInfo.chargeableVolume),
              logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,


              volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam,//体积系数
              declareSupported: x.logisticsBaseInfo.declareSupported,//是否支持一般贸易报关
              logisticsRegionInfo: x.logisticsRegionInfo,//是否支持DDP和签字
              isVatRequired: x.logisticsRegionInfo.isVatRequired,//是否支持Vat

              // 卡车相关
              calculationMethod: x.logisticsBaseInfo.feeCalculationMethod,
              apiAccountId: x.logisticsBaseInfo.apiAccountId,
              truckBaseInfo: x.logisticsBaseInfo.truckBaseInfo,
            }
          })
          state.logistics.selectedLogisticsSchemeId = record.id;
          state.logistics.selectedLogisticsDeliveryType = record.deliveryWay;
          state.logistics.isShowVATInput = record.isVatRequired;
          state.isDeclareSupported = record.declareSupported;

            if (state.plan.toAddressData.isInternationalTransport && state.isDeclareSupported) {
            //默认选中报关,避免客户忘记
              state.isSelectedCustoms = true;
            } else {
              state.isSelectedCustoms = false;
            }

          handleChangeLogisticsScheme()
        }
      }).catch(() => {}).finally(() => {
        state.logisticsSchemeLoading = false;
      })
    }

    const handleDeletePlan = () => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.Are_you_sure_to_delete_p0", [vueI18n.t("warehouse.transport_plan")]),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return deleteTransportPlan({ id: state.plan.id }).then(() => {
            message.success(vueI18n.t("common.delete_success"));
            delVisitedRoute(router.currentRoute.value);
            router.push({ path: "/transport/planlist" });
          });
        },
      })
    }

    const checkFrom = () => {
      if (state.plan.toAddressData.isInternationalTransport) {
        let currencyId = null;
        try {
          state.plan.producTableList.forEach((x, index) => {
            x.productInfos.forEach((product, ind) => {
              if (!product.chName || !product.enName
                || !product.currencyId || !product.declaredPrice || !product.hsCode
              ) {
                currentPageIndex.value = Math.ceil(x.serialNumber / currentPageSize.value);
                throw 1
              }
              if (index == 0 && ind == 0) {
                currencyId = product.currencyId;
              } else {
                if (currencyId != product.currencyId) {
                  currentPageIndex.value = Math.ceil(x.serialNumber / currentPageSize.value);
                  throw 2
                }
              }
            });
          })
        } catch (e) {
          if (e == 1) {
            message.error(vueI18n.t("logistics.customs_clearance_information_incomplete"));
          } else if (e == 2) {
            message.error(vueI18n.t("logistics.currency_inconsistency"));
          }
          return false
        }
      }

      if (!state.logistics.selectedLogisticsSchemeId) {
        message.error(vueI18n.t("logistics.please_select_logistics_scheme"));
        return false
      }
      if (state.logistics.isShowVATInput && !state.logistics.VAT) {
        message.error(vueI18n.t("common.p0_is_required", ['VAT']));
        return false
      }

      // 验证是否需要选择保险
      if (state.plan.toAddressData.isInternationalTransport && state.plan.isInsure === null) {
        message.error(vueI18n.t("common.p0_is_required", ['logistics.purchase_insurance']));
        return false
      }
      //验证上传文件
      if (state.plan.toAddressData.isInternationalTransport && state.isSelectedCustoms) {
        if (state.updateFileType == 'separateOfFile') {
          if (
            !state.files.customsDeclarationUrl ||
            !state.files.contractUrl ||
            !state.files.invoiceUrl ||
            !state.files.packingUrl) {
            message.error(vueI18n.t("logistics.clearance_file_not_upload_accomplish"));
            return false
          }
        } else {
          if (
            !state.files.integrationUrl) {
            message.error(vueI18n.t("logistics.clearance_file_not_upload_accomplish"));
            return false
          }
        }

      }
      return true
    }

    const handleSavePlan = () => {
      if (!checkFrom()) {
        return false
      }
      let transportPlanDetails = state.plan.producTableList?.map(x => {
        let productInfos = x.productInfos.map(item => ({
          boxProductRlId: item.id,
          brandCName: item.chName,
          brandEnName: item.enName,
          hsCode: item.hsCode,
          declarePrice: item.declaredPrice,
          currencyId: item.currencyId,
        }))
        return {
          detailId: x.id,
          productInfos,
        }
      })
      let pra = {
        id: state.plan.id,
        logisticsId: state.logistics.selectedLogisticsSchemeId,
        isChooseDdp: state.logistics.isSelectedDDP,
        isChooseSign: state.logistics.signatureType > 0 ? true : false,
        signatureType: state.logistics.signatureType,
        vatCode: state.logistics.VAT,
        isInsure: state.plan.isInsure === 1 ? true : state.plan.isInsure === 0 ? false : null,
        transportPlanDetails: transportPlanDetails,
        isUploadHsInfo: state.isSelectedCustoms,
        truckApiLogistics: null,
      };
      if (state.updateFileType == 'separateOfFile') {
        state.files.integrationUrl = null;
      } else {
        state.files.customsDeclarationUrl = null;
        state.files.contractUrl = null;
        state.files.invoiceUrl = null;
        state.files.packingUrl = null;
      }
      Object.assign(pra, state.files);
      // 判断是否是卡车
      let selectLogisticsSchemeItem = state.logistics.logisticsSchemeList.find(item => item.id === state.logistics.selectedLogisticsSchemeId)
      if (selectLogisticsSchemeItem && selectLogisticsSchemeItem.calculationMethod === feeCalculationMethodEnum.truckApi) {
        pra.truckApiLogistics = {}
        pra.truckApiLogistics['quoteDetailId'] = selectLogisticsSchemeItem.truckBaseInfo.quoteDetailId
        pra.truckApiLogistics['quoteTypeId'] = selectLogisticsSchemeItem.truckBaseInfo.quoteType?.id ?? null
        pra.truckApiLogistics['serviceLevel'] = selectLogisticsSchemeItem.truckBaseInfo.serviceLevel?.id ?? null
        pra.truckApiLogistics['accessorials'] = selectLogisticsSchemeItem.truckBaseInfo.accessorials?.map(item => item.id) ?? []
      }
      state.pageLoading = true;
      savePlanSetBoxInfo(pra).then((res) => {
        if (res.result) {
          delVisitedRoute(router.currentRoute.value);
          router.push({ path: "/transport/confirmplan/" + res.result });
        }
      }).finally(() => {
        state.pageLoading = false;
      })
    }

    const handleSearchTruckFee = async (record) => {
      if (!record.quoteTypeId) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.quote_type')]))
        return 
      }
      if (!handleShowServiceLevelSelect(record)) {
        record.serviceLevel = null
      } else {
        if (!record.serviceLevel) {
          message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.service_level')]))
          return
        }
      }
      const searchData = {
        planId: state.plan.id,
        logisticsId: record.id,
        truckApiLogistics: {
          quoteTypeId: record.quoteTypeId,
          serviceLevel: record.serviceLevel,
          accessorials: record.accessorials ?? [],
        }
      }
      quotedPriceModalRef.value.open(searchData)
    }

    const handleConfirmQuotedPrice = ({ logisticsId, record }) => {
      let index = state.logistics.logisticsSchemeExhibitionList.findIndex(item => item.id === logisticsId)
      if (index > -1) {
        state.logistics.logisticsSchemeExhibitionList[index].quoteDetail = record
      }
    }

    onMounted(async () => {
      let planId = route.params.id;
      if (planId) {
        state.plan.id = planId;
        funcSearchPlanInfo(planId);
        funcGetCurrencys();
      }
    });

    return {
      ...toRefs(state),
      editBoxSizeModalState,
      getLinkman,
      feeCalculationMethodEnum,
      refPlanName,
      quotedPriceModalRef,
      currentPageIndex,
      currentPageSize,

      handleOpenEditModal,
      handleEditBoxAfter,
      getAddress,
      getAccessorialsName,
      handleOpenOrCloseRow,
      handleSavePlanName,
      handleCancelAlterPlanName,
      handleButtonAlterPlanName,
      toLoadDefaultData,
      handleFreightEstimate,
      handleSelectedLogistics,
      handleDeletePlan,
      handleSavePlan,
      handleConfirmQuotedPrice,
      handleChangeLogisticsScheme,
      handleShowServiceLevelSelect,
      handleSearchTruckFee,
      funcSearchPlanInfo,
    };
  }
})
</script>

<style lang="less" scoped>
.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}
</style>